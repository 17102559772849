





















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import scheduleModule from '@/store/modules/scheduleModule';
import cellRender from './CellRender.js';
// import CellRender from './CellRender.js';
import CustomHeader from './header.js';
import EditableHeader from './headerEditable.js';
import TagSelect from './userTagCell.vue';
import DateSelect from './userDateCell.vue';
import UserSelect from './userSelectCell.vue';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    agColumnEdiable: EditableHeader,
    UserSelect,
    Multiselect,
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TagSelect,
    DateSelect,
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   newColumnName = '';

   newGroupName = '';

  showCreateGroupModal =false;

  selectedWellNames = [];

  availableWellNames = [];

  dataLoading = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags = ['Tag1', 'Tag2']

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

   showWellMenu = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   newCloumnType = '';

   columnDefs: any = [

   ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

    addTag(newTag) {
      if (newTag) {
        this.availableTags.push(newTag);
        this.selectedTags.push(newTag);
      }
    }

    async stopEdit() {
      await this.saveGroupDetailsToBackend();
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const columns = [];
      this.gridApi.hideOverlay();

      if (this.activeGroup && this.activeGroup.Payload) {
        this.rowData = this.activeGroup.Payload;
      }

      if (this.activeGroup && this.activeGroup.Columns) {
        this.activeGroup.Columns.forEach((col) => {
          const hideColumn = false;
          let newCol: any = null;
          if (col.ColumnType === 'WELL') {
            newCol = {
              field: 'Well', headerName: col.CustomName, cellClass: 'noborder', cellStyle: { border: 'none' }, sortable: true, filter: true, width: 300, editable: false, checkboxSelection: false,
            };
            this.columnDefs.push(newCol);
          } else if (col.ColumnType === 'TAGS') {
            newCol = {
              field: col.ColumnName,
              sortable: true,
              editable: hideColumn,
              headerComponent: 'agColumnEdiable',
              cellClass: 'noborder',
              cellStyle: { border: 'none' },
              headerName: col.CustomName,
            
              colId: col.ColumnName,
              initialWidth: 350,
              cellRendererFramework: 'TagSelect',
            };
            this.columnDefs.push(newCol);
          } else if (col.ColumnType === 'DATE') {
            newCol = {
              field: col.ColumnName,
              sortable: true,
              editable: false,
              headerComponent: 'agColumnEdiable',
              cellClass: 'noborder',
              cellStyle: { border: 'none', overflow: 'visible' },
              headerName: col.CustomName,
               suppressRowTransform: true,
              suppressToolPanel: true,
             
              colId: col.ColumnName,
              initialWidth: 150,
              cellRendererFramework: 'DateSelect',
            };
            this.columnDefs.push(newCol);
          } else {
            newCol = {
              field: col.ColumnName,
              headerComponent: 'agColumnEdiable',
              cellStyle: { border: 'none' },
              colId: col.ColumnName,
              cellClass: 'noborder',
              headerName: col.CustomName,
            };
            this.columnDefs.push(newCol);
          }
        });

        const endColumn = {
          field: 'Add Column +',
          sortable: true,
          pinned: 'right',
          cellClass: 'noborder',
          suppressToolPanel: true,
          cellStyle: { border: 'none' },
          headerComponent: 'agColumnHeader1',
          editable: false,

          initialWidth: 120,
          cellRendererFramework: 'cellRender',
          cellRendererParams: {
            clicked(field) {
              alert(`${field} was clicked`);
            },
          },
        };

        this.columnDefs.push(endColumn);
      }
    }

    createGroup() {
      this.selectedTags = [];
      this.selectedWellNames = [];
      this.newGroupName = '';
      this.showCreateGroupModal = false;
    }

    async removeRow(wellName) {
      this.rowData = this.rowData.filter((d) => d.Well !== wellName || !d.Well);
      await this.saveGroupDetailsToBackend();
    }

    async methodFromParent(name, type) {
      console.log(type);
      if (name && type && type === 'Assignee') {
        const data = {
          field: '_assignee',
          sortable: true,
          editable: false,
          headerName: name,
          cellStyle: { border: 'none' },
          cellClass: 'noborder',
          initialWidth: 350,
        };
        this.columnDefs.push(data);
      } else if (name && type && type === 'Tags') {
        const data = {
          field: name.toUpperCase(),
          sortable: true,
          editable: false,
          headerComponent: 'agColumnEdiable',
          cellClass: 'noborder',
          cellStyle: { border: 'none' },
          headerName: name,
         
          colId: name,
          initialWidth: 350,
          cellRendererFramework: 'TagSelect',
        };
        this.columnDefs.push(data);
        // this.rowData = this.rowData.map(d => {
        //   d[name] = []
        //   return d
        // } )
      } else if (name && type && type === 'Date') {
        let counter = 0;
        let isExist = this.columnDefs.filter(d => d.field === "Date")
        console.log(isExist)
        if(isExist){
          counter = isExist.length + 1
        }
        const data = {
          field: `${name.toUpperCase()}`,
          sortable: true,
          editable: false,
          headerComponent: 'agColumnEdiable',
          cellClass: 'noborder',
          cellStyle: { border: 'none' },
          headerName: `${name}`,
          colId: name,
          initialWidth: 160,
          cellRendererFramework: 'DateSelect',

        };

        this.columnDefs.push(data);
      } else {
        this.columnDefs.push({
          field: name.toUpperCase(),
          headerComponent: 'agColumnEdiable',
          cellStyle: { border: 'none' },
          colId: name,
          cellClass: 'noborder',
          headerName: name,
        });
      }

      this.activeGroup.Columns.push({
        ColumnName: name,
        ColumnType: name.toUpperCase(),
        CustomName: name,
      });

      this.gridApi.hideOverlay();
      await this.saveGroupDetailsToBackend();
    }

    get myView() {
      return this.windowWidth < 769 ? 'tasqs-mobile' : 'tasqs-desktop';
    }

    async saveGroupDetailsToBackend() {
      console.log(this.activeGroup);
      const updatedObject = {
        group_id: this.activeGroup.ID,
        columns: JSON.stringify(this.activeGroup.Columns),
        payload: JSON.stringify(this.rowData),
      };

      await scheduleModule.updateGroupDetails(updatedObject);
    }

    beforeMount() {
      this.context = { componentParent: this };
    }

    get availableColumnTypes() {
      return [
        {
          text: 'Text',
          value: 'Text',
        },
        // {
        //   text: 'Date',
        //   value: 'Date',
        // },
        // {
        //   text: 'Assignee',
        //   value: 'assignee',
        // },
      ];
    }

    closeColumnDialog() {
      this.showColumnMenu = false;
    }

    get enabledWells() {
      return assetsModule.enabledWells;
    }

    get activeGroup() {
      return scheduleModule.activeGroup;
    }

    async updateColumnName(oldColName, newColumnName) {
      console.log(oldColName);
      console.log(this.activeGroup.Columns);
      this.activeGroup.Columns = this.activeGroup.Columns.map((c) => {
        if (c.CustomName === oldColName) {
          c.CustomName = newColumnName;
        }
        return c;
      });
      console.log(this.activeGroup.Columns);
      await this.saveGroupDetailsToBackend();
    }

    async updateDataFromCell(index, fieldName, data) {
      this.rowData[index][fieldName] = data;
      console.log( this.rowData[index][fieldName])
      await this.saveGroupDetailsToBackend();
    }

    async created() {
      this.dataLoading = true;
      this.getRowNodeId = (data) => data.Well;
      await assetsModule.getEnabledWells();
      this.availableWellNames = assetsModule.enabledWells.map((w) => w.wellName);
      if(!this.activeGroup){
        this.$router.push({name: 'Groups'})
      }
      await this.sleep(1500)
      this.dataLoading = false;
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

    addColumn() {
      console.log('add');
      console.log(this.newColumnName);
      if (this.newColumnName) {
        this.columnDefs.push({ field: this.newColumnName });
        this.newColumnName = '';
      }
    }

    async removeColumn(columnName) {
      this.columnDefs = this.columnDefs.filter((d) => d.headerName !== columnName);
      this.activeGroup.Columns = this.activeGroup.Columns.filter((d) => d.CustomName !== columnName);
      await this.saveGroupDetailsToBackend()
    }
      sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

    async addRow() {
      console.log('add');
      if (this.newWellName) {
        this.rowData.push({ Well: this.newWellName });
        this.newWellName = '';
      }
      await this.saveGroupDetailsToBackend();
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }

    @Watch('columnDefs')
    updateRowData(data) {
      if (data) {
        this.rowData = this.rowData.map((d) => {
          d._assignee = 'test@gmail.com';
          return d;
        });

        if (data.length > 1 && data[1].field === 'Add Column +' && data[1].pinned === 'left') {
          this.columnDefs[1].pinned = 'right';
        }
      }
    }
}

